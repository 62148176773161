import axios from "axios";

export default {
  namespaced: true,
  state: {
    isChangingPassword: false,
    passwordError: false,
    isUpdatingBillingInfo: false,
    isDeactivatingAccount: false
  },
  mutations: {
    setIsChangingPassword: (state, data) => (state.isChangingPassword = data),
    setPasswordError: (state, data) => (state.passwordError = data),
    setIsUpdatingBillingInfo: (state, data) =>
      (state.isUpdatingBillingInfo = data),
    setIsDeactivatingAccount: (state, data) =>
      (state.isDeactivatingAccount = data)
  },
  actions: {
    changePassword(state, data) {
      state.commit("setIsChangingPassword", true);
      return axios
        .post(`${state.rootState.restAuthUrl}/password/change/`, data)
        .finally(() => state.commit("setIsChangingPassword", false));
    },
    updateBillingInfo(state, data) {
      state.commit("setIsUpdatingBillingInfo", true);
      return axios
        .patch(`${state.rootState.restAuthUrl}/user/`, {
          ...data,
          country: data.country.code,
          entity: data.isLegalEntity ? "Legal_entity" : "Natural_person"
        })
        .then(() => state.commit("updateUser", data, { root: true }))
        .finally(() => state.commit("setIsUpdatingBillingInfo", false));
    },
    deactivateAccount(state, data) {
      state.commit("setIsDeactivatingAccount", true);
      return axios
        .post(`${state.rootState.restAuthUrl}/deactivate-account/`, data)
        .finally(() => state.commit("setIsDeactivatingAccount", false));
    }
  },
  getters: {
    getChangingPasswordStatus: state => state.isChangingPassword,
    getPasswordError: state => state.passwordError,
    isUpdatingBillingInfo: state => state.isUpdatingBillingInfo,
    isDeactivatingAccount: state => state.isDeactivatingAccount
  }
};
