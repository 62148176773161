import * as constants from "./constants";
import moment from "moment";

export function getBaseURL(isAuth, isPlain) {
  const env = process.env.VUE_APP_ENV;
  const route = isPlain ? "" : isAuth ? "/rest-auth" : "/api";
  const url =
    env === "dev"
      ? "https://dev.analyticalplatform.com"
      : env === "test"
      ? "https://test.analyticalplatform.com"
      : env === "live"
      ? "https://app.analyticalplatform.com"
      : env === "local"
      ? "http://localhost"
      : env;

  return `${url}${route}`;
}

export function getFactorInvestingURL() {
  const env = process.env.VUE_APP_ENV;
  const url = env === "dev"
    ? "https://dev.analyticalplatform.com"
    : env === "test"
    ? "https://test.factors.analyticalplatform.com"
    : env === "live"
    ? "https://factors.analyticalplatform.com"
    : env === "local"
    ? "http://localhost:3000/"
    : env;

  return url;
}

export function getParsedObject(object) {
  if (object) {
    return JSON.parse(JSON.stringify(object));
  }
  return [];
}

export function filterByReference(arr1, arr2) {
  let res = [];
  res = arr1.filter(el => {
    return !arr2.find(element => {
      return element.id === el.id;
    });
  });
  return res;
}

export function getMyReportsDataFormat(item) {
  return {
    id: item.id,
    valid_from: item.valid_from,
    state: item.state,
    type: item.type,
    profit: item.profit,
    expiration: item.expiration,
    next_rebalance: item.next_rebalance
  };
}

//report privileges access
export function hasAccess(report, items) {
  const state = report.state.toUpperCase();
  const type = report.type.toUpperCase();
  switch (state) {
    case constants.NEW_REPORT: {
      if (items.includes(constants.NEW_REPORT_PRIVILEGE)) {
        return true;
      }
      if (
        items.includes(constants.NEW_REPORT_LIMITED_PRIVILEGE) &&
        type !== constants.YEARLY_REPORT
      ) {
        return true;
      }
      return false;
    }
    case constants.OUTDATED_REPORT: {
      if (
        items.includes(constants.OUTDATED_REPORT_YEARLY_PRIVILEGE) &&
        items.includes(constants.OUTDATED_REPORT_DAILY_MONTHLY_PRIVILEGE)
      ) {
        return true;
      }
      if (
        items.includes(constants.OUTDATED_REPORT_YEARLY_PRIVILEGE) &&
        type === constants.YEARLY_REPORT
      ) {
        return true;
      }
      if (
        items.includes(constants.OUTDATED_REPORT_DAILY_MONTHLY_PRIVILEGE) &&
        type !== constants.YEARLY_REPORT
      ) {
        return true;
      }
      return false;
    }
    case constants.EXPIRED_REPORT:
      return true;
    default:
      return false;
  }
}

export const formatDateToCommon = date =>
  moment(date).format(constants.DATE_TIME_FORMAT());

export function transformTimeSlotProps(props) {
  const formattedProps = {};
  Object.entries(props).forEach(([key, value]) => {
    formattedProps[key] =
      value < 10 && key !== "totalDays" ? `0${value}` : String(value);
  });

  return formattedProps;
}

export const strategyApiTranslator = (value, to) => {
  const counterPart = to === "app" ? "api" : "app";
  const pairs = [
    { app: constants.LONG_ONLY, api: constants.API_LONG_ONLY },
    { app: constants.BALANCED_STRATEGY, api: constants.API_BALANCED_STRATEGY }
  ];
  return pairs.find(pair => pair[counterPart] === value)[to];
};
