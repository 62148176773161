const ANPAdmin = () =>
  import(/* webpackChunkName: "admin" */ "./pages/Admin.vue");
const Deactivated = () => import("./pages/Deactivated.vue");
const EditReport = () =>
  import(/* webpackChunkName: "admin" */ "./pages/EditReport.vue");
const FirstStepWizzard = () =>
  import(
    /* webpackChunkName: "wizzard" */ "./components/wizzard/FirstStepWizzard"
  );
const ForgottenPassword = () =>
  import(/* webpackChunkName: "onBoarding" */ "./pages/ForgottenPassword");
const FourthStepWizzard = () =>
  import(
    /* webpackChunkName: "wizzard" */ "./components/wizzard/FourthStepWizzard"
  );
const LandingLayout = () =>
  import(/* webpackChunkName: "onBoarding" */ "./layouts/LandingLayout");

const LandingSidebar = () =>
  import(
    /* webpackChunkName: "onBoarding" */ "./components/sidebars/LandingSidebar"
  );
const Login = () =>
  import(/* webpackChunkName: "onBoarding" */ "./pages/Login");
const NotFound = () =>
  import(/*webpackChunkName: "NotFound"*/ "./pages/NotFound");
const PatternLab = () => import("./pages/PatternLab");
// const Preferences = () =>
//   import(/* webpackChunkName: "profile" */ "./pages/profile/Preferences");
const PrivacyPolicy = () => import("./pages/PrivacyPolicy.vue");
const Profile = () =>
  import(/* webpackChunkName: "onBoarding" */ "./pages/profile/Profile");
const Register = () =>
  import(/* webpackChunkName: "onBoarding" */ "./pages/Register");
const ReportDetail = () =>
  import(/* webpackChunkName: "SpLab" */ "./pages/ReportDetail");
const StockRanking = () =>
  import(/* webpackChunkName: "SpLab" */ "./pages/StockRanking");
  
const ResetPassword = () => import("./pages/ResetPassword");
const SecondStepWizzard = () =>
  import(
    /* webpackChunkName: "wizzard" */ "./components/wizzard/SecondStepWizzard"
  );
const StockPickingLab = () =>
  import(/* webpackChunkName: "SpLab" */ "./pages/StockPickingLab");
// const Subscription = () =>
//   import(/* webpackChunkName: "profile" */ "./pages/profile/Subscription");
const TermsAndConditions = () => import("./pages/TermsAndConditions.vue");
const ThirdStepWizzard = () =>
  import(
    /* webpackChunkName: "wizzard" */ "./components/wizzard/ThirdStepWizzard"
  );
const UserInfoAndSettings = () =>
  import(
    /* webpackChunkName: "profile" */ "./pages/profile/UserInfoAndSettings"
  );
const StockTaxes = () =>
  import(
    /* webpackChunkName: "taxes" */ "./pages/StockTaxes"
  );
const VerifyEmail = () => import("./pages/VerifyEmail");
import VueRouter from "vue-router";
import { WIZZARD_STEPS } from "./utils/constants";
const Welcome = () =>
  import(/* webpackChunkName: "notFound" */ "./pages/Welcome");
const Wizzard = () =>
  import(/* webpackChunkName: "wizzard" */ "./pages/Wizzard.vue");
import imgEmailSent from "./assets/img/infographics/email_sent.png";
import imgLoginWoman from "./assets/img/infographics/registration_woman.png";
import imgRegistrationMan from "./assets/img/infographics/registration_man.png";
import { pathPermissions } from "./utils/constants";
import store from "./store/index";

const routes = [
  {
    path: "/",
    components: {
      default: LandingLayout
    },
    children: [
      {
        path: "",
        name: "Login",
        components: {
          default: Login,
          sidebar: LandingSidebar
        },
        props: {
          sidebar: {
            image: imgLoginWoman
          }
        }
      },
      {
        path: "/register",
        name: "Registration",
        components: {
          default: Register,
          sidebar: LandingSidebar
        },
        props: {
          sidebar: {
            image: imgRegistrationMan
          }
        }
      },
      {
        path: "/forgottenPassword",
        name: "ForgottenPassword",
        components: {
          default: ForgottenPassword,
          sidebar: LandingSidebar
        },
        props: {
          sidebar: {
            image: imgEmailSent
          }
        }
      },
      {
        path: "/fe/verify-register/:key",
        name: "VerifyRegistration",
        components: {
          default: VerifyEmail,
          sidebar: LandingSidebar
        },
        props: {
          sidebar: {
            image: imgEmailSent
          }
        }
      },
      {
        path: "/fe/verify-reset/:uid/:token",
        name: "VerifyReset",
        components: {
          default: ResetPassword,
          sidebar: LandingSidebar
        },
        props: {
          sidebar: {
            image: imgEmailSent
          }
        }
      },
      {
        path: "/deactivated",
        components: {
          default: Deactivated,
          sidebar: LandingSidebar
        },
        name: "Deactivated"
      }
    ]
  },
  {
    path: "/stockPickingLab",
    component: ReportDetail,
    name: "StockPickingLab",
    props: true
  },
  {
    path: "/stockRanking",
    component: StockRanking,
    name: "StockRanking",
    props: true
  },
  {
    path: "/stockPickingLab/reportsList/:timeframe",
    name: "reportList",
    component: StockPickingLab,
    props: true
  },
  {
    path: "/stockPickingLab/reportDetail/:id",
    name: "reportDetail",
    component: ReportDetail,
    props: true
  },
  {
    path: "/stockPickingLab/editReport/:id",
    name: "editReport",
    component: EditReport
  },
  {
    path: "/welcome",
    component: Welcome,
    name: "Welcome"
  },
  {
    path: "/wizzard",
    component: Wizzard,
    children: [
      {
        path: "",
        component: FirstStepWizzard,
        name: "spWizzard"
      },
      {
        path: WIZZARD_STEPS[1],
        component: SecondStepWizzard,
        name: "spWizzard2"
      },
      {
        path: WIZZARD_STEPS[2],
        component: ThirdStepWizzard,
        name: "spWizzard3"
      },
      {
        path: WIZZARD_STEPS[3],
        component: FourthStepWizzard,
        name: "spWizzard4"
      }
    ]
  },
  {
    path: "/patternLab",
    component: PatternLab
  },
  {
    path: "/taxes",
    component: StockTaxes,
    name: "Taxes",
    props: true
  },
  {
    path: "/profile",
    component: Profile,
    children: [
      {
        path: "",
        component: UserInfoAndSettings
      },
      // {
      //   path: "/profile/preferences",
      //   component: Preferences
      // },
      // {
      //   path: "/profile/subscription",
      //   name: "subscription",
      //   component: Subscription
      // }
    ]
  },
  {
    path: "/termsAndConditions",
    component: TermsAndConditions,
    name: "termsAndConditions"
  },
  {
    path: "/privacyPolicy",
    component: PrivacyPolicy,
    name: "privacyPolicy"
  },
  {
    path: "/anp-admin",
    component: ANPAdmin,
    name: "ANPAdmin"
  },
  {
    path: "*",
    component: NotFound
  }
];

const router = new VueRouter({ routes, mode: "history" });

router.beforeEach((to, from, next) => {
  const isOnNoAuthPage =
    [
      "Login",
      "Registration",
      "ForgottenPassword",
      "VerifyRegistration",
      "VerifyReset"
    ].indexOf(to.name) >= 0;

  const isOnGeneralyAccesiblePage =
    ["termsAndConditions", "privacyPolicy"].indexOf(to.name) >= 0;

  if (to.name === "Deactivated") {
    store.commit("deleteUser");
    window.$cookies.remove("accessToken", undefined, ".analyticalplatform.com");
    localStorage.removeItem("refreshToken");
    next();
  } else if (isOnGeneralyAccesiblePage) {
    store.commit("setIsInitializing", false);
    next();
  } else if (
    isOnNoAuthPage &&
    store.getters.getUser &&
    !store.getters.isLoggingOut
  ) {
    next({ name: "Welcome" });
  } else if (store.getters.getUser) {
    if (store.getters.getInitStatus) {
      store.commit("setIsInitializing", false);
    }
    next();
  } else {
    if (window.$cookies.isKey("accessToken")) {
      store
        .dispatch("checkToken")
        .then(() => {
          const permissions = store.getters.getUser.permissions;
          const path = Object.keys(pathPermissions).find(p => to.path.startsWith(p));

          if (isOnNoAuthPage) {
            next({ name: "Welcome" });
          } else if (!isOnNoAuthPage && path && !permissions.includes(pathPermissions[path])) {
            next({ name: "Welcome" });
          } else {
            next();
          }
        })
        .catch(() => {
          window.$cookies.remove("accessToken", undefined, ".analyticalplatform.com");
          localStorage.removeItem("refreshToken");
          next({ name: "Login" });
        });
    } else {
      store.commit("setIsInitializing", false);
      if (isOnNoAuthPage) {
        next();
      } else {
        next({ name: "Login" });
      }
    }
  }
});

export default router;
