import MainAlert from "../components/alerts/MainAlert.vue";
import { TIMEFRAMES } from "../utils/constants";
import axios from "axios";
import { formatDateToCommon } from "../utils/helper";
import i18n from "../i18n";
import moment from "moment";
/* import createAuthRefreshInterceptor from "axios-auth-refresh";
import Vue from "vue"; */
/**
 * Momentjs returns the correct date format for xAxis.
 *
 * @param {String} format Date format from the time frame.
 * @param {Number} date Date of xAxis.
 * @return {String} correct date format.
 */

export default {
  namespaced: true,
  state: {
    // Candlestick Chart
    xAxis: [],
    serial: [],
    lowData: [],
    markPointsLow: [],
    markPointsBig: [],
    date: {
      from: moment()
        .subtract(1, "days")
        .subtract(1, "year"),
      to: moment().subtract(1, "days")
    },
    signalCount: {},

    // Control Menu
    ticker: null,
    frame: null,
    frameName: "",
    pattern: null,
    listOfPatterns: [],
    timeFrames: TIMEFRAMES(),

    //Trading simulator

    initialCapital: null,

    // Timeline chart
    buyAndHold: [],
    statusData: null,
    balanceSeries: [],

    // My pattern result
    patternResults: [],
    selectedResult: null,
    isGettingPatternResults: false,
    selectedTrades: null,

    // Others
    loading: false,
    visibility: false,
    simulation: false
  },

  mutations: {
    setxAxisData: (state, data) => (state.xAxis = data),
    setSeriesData: (state, data) => (state.serial = data),
    setHistoryLowData: (state, data) => (state.lowData = data),
    setMarkPointsLow: (state, data) => (state.markPointsLow = data),
    setMarkPointsBig: (state, data) => (state.markPointsBig = data),
    setDate: (state, data) => (state.date = data),

    setTicker: (state, data) => (state.ticker = data),
    setFrame: (state, data) => (state.frame = data),
    setFrameName: (state, data) => (state.frameName = data),
    setPattern: (state, data) => (state.pattern = data),
    setListOfPatterns: (state, data) => (state.listOfPatterns = data),
    setSignalCount: (state, data) => (state.signalCount = data),

    setBuyAndHold: (state, data) => (state.buyAndHold = data),
    setStatusData: (state, data) => (state.statusData = data),
    setBalanceSeries: (state, data) => (state.balanceSeries = data),

    setMyPatternResults: (state, data) => (state.patternResults = data),
    setSelectedResult: (state, data) => (state.selectedResult = data),
    setIsGettingPatternResults: (state, data) =>
      (state.isGettingPatternResults = data),
    removeMyPatternResult: (state, data) =>
      (state.patternResults = state.patternResults.filter(
        pr => pr.id !== data
      )),
    addPatternResultAlert: (state, data) =>
      (state.patternResults = state.patternResults.map(pr => {
        if (pr.id === data.id) {
          return {
            ...pr,
            id_alert: data.id_alert
          };
        } else if (
          pr.asset === data.ticker &&
          pr.pattern === data.pattern &&
          pr.time_frame === data.time_frame
        ) {
          return {
            ...pr,
            id_alert: undefined
          };
        } else {
          return pr;
        }
      })),
    removePatternResultAlert: (state, data) =>
      (state.patternResults = state.patternResults.map(pr => {
        if (pr.id_alert === data) {
          return {
            ...pr,
            id_alert: null
          };
        } else {
          return pr;
        }
      })),

    setLoading: (state, data) => (state.loading = data),
    setVisibility: (state, data) => (state.visibility = data),
    setSimulation: (state, data) => (state.simulation = data),
    setSelectedTrades: (state, data) => (state.selectedTrades = data),
    setTimeFrames: (state, data) => (state.timeFrames = data)
  },

  actions: {
    generatePDFResult(store, data) {
      return axios.get(`/pl2/PatternResults/${data}/pdf`, {
        responseType: "arraybuffer"
      });
    },
    getMyPatternResults({ commit }) {
      commit("setIsGettingPatternResults", true);
      const perPage = 10000;
      axios
        .get(`/pl2/MyPatternResults?page_size=${perPage}`)
        .then(patternResultsResponse => {
          axios
            .get("/pl2/Alerts")
            .then(alertsResponse => {
              const patternResults = patternResultsResponse.data.results.map(
                patternResult => ({
                  ...patternResult,
                  id_alert: alertsResponse.data.find(
                    alert => alert.pattern_result === patternResult.id
                  )?.id
                })
              );
              commit("setMyPatternResults", patternResults.reverse());
            })
            .finally(() => commit("setIsGettingPatternResults", false));
        })
        .catch(() => {
          this._vm.$toast.error({
            component: MainAlert,
            props: {
              message: i18n.t("chartStore.getMyPatternResultsError")
            }
          });
        });
    },

    async getChartData({ commit, state }) {
      if (state.simulation == true) {
        commit("setSimulation", false);
        state.buyAndHold = [];
        state.balanceSeries = [];
        state.statusData = null;
      }

      commit("setLoading", true);
      const DWURL = `/datawarehouse/History/${state.ticker.symbol}/${
        state.frame.id
      }?from=${moment(state.date.from).format("YYYYMMDD")}&to=${moment(
        state.date.to
      ).format("YYYYMMDD")}`;

      let graphValues = [];
      let date = [];
      let lowDataValue = [];
      let lowPoints = [];
      let bigPoints = [];

      await axios
        .get(DWURL)
        .then(results => {
          date = Object.keys(results.data.Open).map(date =>
            formatDateToCommon(date)
          );

          Object.keys(results.data.Open).forEach(key => {
            graphValues.push([
              results.data.Open[key].toFixed(2),
              results.data.Close[key].toFixed(2),
              results.data.Low[key].toFixed(2),
              results.data.High[key].toFixed(2)
            ]);
            lowDataValue.push({
              id: formatDateToCommon(key),
              value: results.data.Low[key].toFixed(2)
            });
          });

          commit("setxAxisData", date);
          commit("setSeriesData", graphValues);
          commit("setHistoryLowData", lowDataValue);
        })
        .catch(() => {
          this._vm.$toast.error({
            component: MainAlert,
            props: {
              message: i18n.t("chartStore.candleStickError")
            }
          });
        });

      const perPage = 100000;
      const SURL = `/pl2/Signals?finish_date=${moment(state.date.to).format(
        "YYYY-MM-DD"
      )}&format=json&page_size=${perPage}&patterns=${
        state.pattern.id
      }%2C14&start_date=${moment(state.date.from).format(
        "YYYY-MM-DD"
      )}&symbol=${state.ticker.symbol}&timeframe=${state.frame.id}`;

      await axios
        .get(SURL)
        .then(signal => {
          let bullish = 0;
          let bearish = 0;

          signal.data.results.forEach(pattern => {
            const patternsValue = lowDataValue.find(
              hl => hl.id === formatDateToCommon(pattern.date)
            ).value;
            if (pattern.direction > 0) {
              bullish++;
              bigPoints.push({
                xAxis: formatDateToCommon(pattern.date),
                yAxis: (patternsValue * 0.95).toFixed(2)
              });
            } else {
              bearish++;
              lowPoints.push({
                xAxis: formatDateToCommon(pattern.date),
                yAxis: (patternsValue * 0.95).toFixed(2)
              });
            }
          });

          commit("setSignalCount", { bullish, bearish });
          commit("setMarkPointsLow", lowPoints);
          commit("setMarkPointsBig", bigPoints);
          commit("setLoading", false);
        })
        .catch(() => {
          this._vm.$toast.error({
            component: MainAlert,
            props: {
              message: i18n.t("chartStore.signalError")
            }
          });
        })
        .finally(() => commit("setVisibility", true));
    },

    // Takes data from the detail backtest and puts it into a chart
    getChartFromBeckTest({ commit, dispatch, state }, data) {
      state.buyAndHold = [];
      state.balanceSeries = [];

      commit("setTicker", data.history.asset);
      commit("setFrame", state.timeFrames[data.history.time_frame - 1]);
      commit("setPattern", { id: data.pattern, name: data.pattern_name });
      commit("setFrameName", state.timeFrames[data.history.time_frame - 1]);
      commit("setDate", { from: data.start_date, to: data.finish_date });
      commit("setBuyAndHold", Object.values(data.buy_and_hold));
      commit("setBalanceSeries", Object.values(data.balance_series));
      commit("setStatusData", data);
      dispatch("getChartData").finally(() => commit("setSimulation", true));
    },

    getListOfPatterns(state) {
      const URL = "/pl2/Pattern?format=json";
      axios
        .get(URL)
        .then(pattern => {
          state.commit("setListOfPatterns", pattern.data);
        })
        .catch(() => {
          this._vm.$toast.error({
            component: MainAlert,
            props: {
              message: i18n.t("chartStore.patternListError")
            }
          });
        });
    },

    clearData({ commit }) {
      let date = {
        from: moment()
          .subtract(1, "days")
          .subtract(1, "year"),
        to: moment().subtract(1, "days")
      };
      commit("setxAxisData", []);
      commit("setSeriesData", []);
      commit("setHistoryLowData", []);
      commit("setMarkPointsLow", []);
      commit("setMarkPointsBig", []);
      commit("setDate", date);
      commit("setBuyAndHold", []);
      commit("setBalanceSeries", []);
      commit("setStatusData", null);
      commit("setPattern", null);
      commit("setTicker", null);
      commit("setFrame", null);
      commit("setSelectedResult", null);
      commit("setSignalCount", {});
    }
  },

  getters: {
    getxAxisData: state => state.xAxis,
    getSeriesData: state => state.serial,
    getMarkPointsLow: state => state.markPointsLow,
    getMarkPointsBig: state => state.markPointsBig,
    getDate: state => state.date,
    getSignalCount: state => state.signalCount,

    getTicker: state => state.ticker,
    getPattern: state => state.pattern,
    getFrame: state => state.frame,
    getFrameName: state => state.frameName,
    getTimeFrames: state => state.timeFrames,
    getListOfPatterns: state => state.listOfPatterns,

    getBuyAndHold: state => state.buyAndHold,
    getBalanceSeries: state => state.balanceSeries,
    getStatusData: state => state.statusData,

    getMyPatternResults: state => state.patternResults,
    getSelectedResult: state => state.selectedResult,
    isGettingPatternResults: state => state.isGettingPatternResults,

    getLoading: state => state.loading,
    getVisibility: state => state.visibility,
    getSimulation: state => state.simulation,
    selectedTrades: state => state.selectedTrades
  }
};
