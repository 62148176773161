<template>
  <div class="main-alert">
    {{ message }}
  </div>
</template>

<script>
export default {
  name: "main-alert",
  props: {
    message: {
      type: String,
      description: "Message to be displayed in the alert",
    },
  },
};
</script>

<style lang="scss" scoped>
.main-alert {
  font-size: toRem(16);
}
</style>