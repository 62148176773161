import "./assets/styles/index.scss";
import "./validationRules";
import "vue-editable-grid/dist/VueEditableGrid.css";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import App from "./App.vue";
import { BootstrapVue } from "bootstrap-vue";
import { GLOBAL_TOAST_OPTIONS } from "./utils/constants";
import { GroupChannelModule } from "@sendbird/chat/groupChannel";
import SendbirdChat from "@sendbird/chat";
import Toast from "vue-toastification";
import VCalendar from "v-calendar";
import Vue from "vue";
import VueCarousel from "vue-carousel";
import VueCookies from "vue-cookies";
import VueCountdown from "@chenfengyuan/vue-countdown";
import VueEditableGrid from "vue-editable-grid";
import VueGtag from "vue-gtag";
import VueMoment from "vue-moment";
import VueNumberFormat from "vue-number-format";

import VueRouter from "vue-router";

import axios from "axios";
import { getBaseURL } from "./utils/helper";
import i18n from "./i18n";
import moment from "moment";
import router from "./router";
import store from "./store";
import vSelect from "vue-select";
import vueDebounce from "vue-debounce";

Vue.use(Toast, GLOBAL_TOAST_OPTIONS);
Vue.component(VueCountdown.name, VueCountdown);
Vue.component("v-select", vSelect);
Vue.component("vue-editable-grid", VueEditableGrid);
Vue.config.productionTip = false;
Vue.use(VueCookies, {
  domain: location.hostname.replace(/(test.|app.)/i, "")
});
Vue.use(BootstrapVue);
Vue.use(VueCarousel);
Vue.use(VueRouter);
Vue.use(VueMoment, moment);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.use(VCalendar, { componentPrefix: "vc" });
Vue.use(vueDebounce);
Vue.use(VueNumberFormat, { decimal: ".", thousand: " ", prefix: "$" });
Vue.use(
  VueGtag,
  {
    config: {
      id: "G-0HY7P7TCQJ"
    }
  },
  router
);

axios.defaults.baseURL = getBaseURL(false);

const sb = SendbirdChat.init({
  appId: "5384744D-003F-462E-898D-AB0D1725C0D4",
  modules: [new GroupChannelModule()]
});

Vue.prototype.$sb = sb;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
