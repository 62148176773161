import axios from "axios";

export default {
  namespaced: true,
  state: {
    subscriptionHistory: [],
    totalRows: null,
    isLoadingSubscriptionHistory: true,
    fetchedPages: []
  },
  mutations: {
    setSubscriptionHistory: (state, data) => {
      state.subscriptionHistory = [
        ...state.subscriptionHistory,
        {
          ...data
        }
      ];
      state.fetchedPages = [...state.fetchedPages, data.pageNo];
    },
    setTotalRows: (state, data) => (state.totalRows = data),
    setIsLoadingSubscriptionHistory: (state, data) =>
      (state.isLoadingSubscriptionHistory = data)
  },
  actions: {
    getSubscriptionHistory: (state, data = 1) => {
      state.commit("setIsLoadingSubscriptionHistory", true);
      return axios
        .get("/subscription/subscriptions/", {
          params: {
            page: data
          }
        })
        .then(res => {
          state.commit("setTotalRows", res.data.count);
          state.commit("setSubscriptionHistory", {
            rows: res.data.results,
            pageNo: data
          });
        })
        .finally(() => state.commit("setIsLoadingSubscriptionHistory", false));
    }
  },
  getters: {
    subscriptionHistory: state => state.subscriptionHistory,
    totalRows: state => state.totalRows,
    isLoadingSubscriptionHistory: state => state.isLoadingSubscriptionHistory,
    fetchedPages: state => state.fetchedPages
  }
};
