import {
  getStrategyTranslations,
  getTypeTranslation
} from "./translationHelper";
import ErrorIcon from "vue-material-design-icons/AlertCircle";
import InfoIcon from "vue-material-design-icons/Information";
import SuccessIcon from "vue-material-design-icons/CheckCircle";
import { TYPE } from "vue-toastification";
import i18n from "../i18n";
import moment from "moment";

export const DAILY_REPORT = "DAILY";
export const MONTHLY_REPORT = "MONTHLY";
export const YEARLY_REPORT = "YEARLY";

export const BALANCED_STRATEGY = "longShort";
export const API_BALANCED_STRATEGY = "20/20";
export const LONG_ONLY = "longOnly";
export const API_LONG_ONLY = "Longs_only";

export const ALL = "all";
export const EXPIRED_REPORT = "EXPIRED";
export const MY_REPORT = "MY-REPORT";
export const NEW_REPORT = "NEW";
export const OUTDATED_REPORT = "OUTDATED";

export const TICKER_LONG = "Long";
export const TICKER_SHORT = "Short";

export const DETAIL_UPGRADE = "DETAIL_UPGRADE";
export const REPORT_UPGRADE = "REPORT_UPGRADE";

export const DAY = "1d";
export const FIVE_DAYS = "5d";
export const MONTH = "m";
export const DEFAULT = "default";

export const ICONS_FILL_COLOR = "#3687FF";

// report privileges
export const REBALANCE_PRIVILEGE = "IBKR_rebalance";
export const EXPIRED_REPORT_PRIVILEGE = "SP_light_Expired_reports";

export const NEW_REPORT_LIMITED_PRIVILEGE =
  "SP_light_New_daily_monthly_reports";
export const NEW_REPORT_PRIVILEGE = "SP_light_New_reports";

export const OUTDATED_REPORT_YEARLY_PRIVILEGE =
  "SP_light_Outdated_yearly_reports_unlimited";
export const OUTDATED_REPORT_DAILY_MONTHLY_PRIVILEGE =
  "SP_light_Outdated_daily_monthly_reports_unlimited";

export const SCORE_PRIVILEGE = "SP_score";
export const ALL_ASSETS_PRIVILEGE = "SP_all_assets";
export const SCORE_PRICE_CORRELATION_PRIVILEGE = "SP_score_price_correlation";
export const ALL_REPORTS_PRIVILEGE = "SP_all_reports";
export const SFA_ARTICLES_PRIVILEGE = "SFA_articles";
export const SFA_ADVANCED_PRIVILEGE = "SFA_advanced";
export const BROKER_INTEGRATION_PRIVILEGE = "Broker_integration";
export const MONTHLY_LONG_ONLY_PRIVILEGE = "Strategy_aggressive_monthly";
export const YEARLY_LONG_ONLY_PRIVILEGE = "Strategy_aggressive_yearly";
export const MONTHLY_BALANCED_PRIVILEGE = "Strategy_hedged_monthly";
export const YEARLY_BALANCED_PRIVILEGE = "Strategy_hedged_yearly";
export const PORTFOLIO_PRIVILEGE = "Portfolio_optimization";
export const TAX_PRIVILEGE = "Tax_app";
export const PHONE_PRIVILEGE = "Phone_support";
export const IBKR_REGISTRATION_PRIVILEGE = "IBKR_registration_support";
export const PATTERN_LAB_PRIVILEGE = "Pattern_lab";
export const ANALYTICAL_DATA_PRIVILEGE= "Analytical_data";

export const LONG_ONLY_PRIVILEGE = "Aggressive_strategy";
export const BALANCED_PRIVILEGE = "Hedged_strategy";
export const INVESTMENT_STRATEGIES_PRIVILEGE = "Investment_strategies";
export const EXCLUSIVE_APPS_PRIVILEGE = "Exclusive_apps";

export const STRATEGIES = [
  MONTHLY_BALANCED_PRIVILEGE,
  YEARLY_BALANCED_PRIVILEGE,
  MONTHLY_LONG_ONLY_PRIVILEGE,
  YEARLY_LONG_ONLY_PRIVILEGE
];

/* Global settings for alerts */
export const GLOBAL_TOAST_OPTIONS = {
  position: "bottom-left",
  icon: false,
  hideProgressBar: true,
  toastDefaults: {
    [TYPE.SUCCESS]: {
      timeout: 4000,
      icon: SuccessIcon
    },
    [TYPE.ERROR]: {
      timeout: 4000,
      icon: ErrorIcon
    },
    [TYPE.INFO]: {
      timeout: 4000,
      icon: InfoIcon
    }
  }
};

export const BILLING_CYCLE_OPTIONS = () => [
  {
    id: "monthly",
    name: i18n.t("subscription.order.monthly")
  },
  {
    id: "annually",
    name: i18n.t("subscription.order.yearly")
  }
];

export const DELETE_ACC_OPTIONS = [
  {
    id: 0,
    name: "temporaryAcc"
  },
  {
    id: 1,
    name: "noLongerInterested"
  },
  {
    id: 2,
    name: "alternative"
  },
  {
    id: 3,
    name: "price"
  },
  {
    id: 4,
    name: "needs"
  },
  {
    id: 5,
    name: "service"
  },
  {
    id: 6,
    name: "other"
  }
];

export const STRIPE_PK = process.env.VUE_APP_STRIPE_PK;

export const PASSWORD_REGEX = /^(?=.*)(?=.*\d).{8,}$/;

//^(?=.*)(?=.*\d).{8,}$
//^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d|€÷×$<>!@#$&()\-/`.+*,]{8,}$

export const TIER_PRODUCTS = {
  T0: [
    "limitedStockPickingLab",
    "limitedMonthlyReports",
    "limitedDailyReports",
    "fullAccessToPatternLab"
  ],
  T1: [
    "limitedStockPickingLab",
    "regularMonthlyReports",
    "regularDailyReports",
    "limitedAnnualReports",
    "fullAccessToPatternLab"
  ],
  T2: [
    "fullAccessToStockPickingLab",
    "regularMonthlyReports",
    "IBKRRebalance",
    "regularAllReports",
    "taxAppAndAdvisory",
    "fullAccessToPatternLab"
  ]
};

export const ALL_PRODUCTS = {
  portfolioOptimization: "Portfolio_optimization",
  tax: "Tax_app",
  factorInvesting: "Factor_investing_basic",
  marketSentiment: "SFA_articles",
  patternLab: "Pattern_lab",
  stockPickingLab: "Stockpicker",
  stockRanking: "IBKR_rebalance"
};

export const pathPermissions = {
  "/optimization-fe/portfolioManager":  ALL_PRODUCTS.portfolioOptimization,
  "/stockPickingLab": ALL_PRODUCTS.stockPickingLab,
  "/taxes": ALL_PRODUCTS.tax,
  "/optimization-fe/marketSentiment": ALL_PRODUCTS.marketSentiment,
  "/stockRanking": ALL_PRODUCTS.stockRanking,
  "/patternLab": ALL_PRODUCTS.patternLab
};

export const PRICE_MOVE_TIME_FRAME_OPTIONS = () => [
  {
    id: DEFAULT,
    name: i18n.t("general.default")
  },
  {
    id: DAY,
    name: "1D"
  },
  {
    id: FIVE_DAYS,
    name: i18n.t("shortcuts.fiveDays")
  },
  {
    id: MONTH,
    name: "1M"
  }
];
export const CANDLESTICK_MULTISWITCH_VALUES = [
  {
    id: "d",
    name: i18n.t("shortcuts.day"),
    from: moment()
      .subtract(2, "day")
      .format("YYYY-MM-DD"),
    to: moment()
      .subtract(1, "day")
      .format("YYYY-MM-DD"),
    maxFrame: 1
  },
  {
    id: "w",
    name: i18n.t("shortcuts.fiveDays"),
    from: moment()
      .subtract(1, "week")
      .format("YYYY-MM-DD"),
    to: moment()
      .subtract(1, "day")
      .format("YYYY-MM-DD"),
    maxFrame: 2
  },
  {
    id: "m",
    name: "1m",
    from: moment()
      .subtract(1, "month")
      .format("YYYY-MM-DD"),
    to: moment()
      .subtract(1, "day")
      .format("YYYY-MM-DD"),
    maxFrame: 3
  },
  {
    id: "6m",
    name: "6m",
    from: moment()
      .subtract(6, "month")
      .format("YYYY-MM-DD"),
    to: moment()
      .subtract(1, "day")
      .format("YYYY-MM-DD"),
    maxFrame: 3
  },
  {
    id: "ytd",
    name: "ytd",
    from: moment()
      .startOf("year")
      .format("YYYY-MM-DD"),
    to: moment()
      .subtract(1, "day")
      .format("YYYY-MM-DD"),
    maxFrame: 3
  },
  {
    id: "y",
    name: "1" + i18n.t("shortcuts.year"),
    from: moment()
      .subtract(1, "year")
      .format("YYYY-MM-DD"),
    to: moment()
      .subtract(1, "day")
      .format("YYYY-MM-DD"),
    maxFrame: 3
  },
  {
    id: "5y",
    name: "5" + i18n.t("shortcuts.year"),
    from: moment()
      .subtract(5, "year")
      .format("YYYY-MM-DD"),
    to: moment()
      .subtract(1, "day")
      .format("YYYY-MM-DD"),
    maxFrame: 3
  },
  {
    id: "c",
    name: i18n.t("candlestickChart.custom")
  }
];

export const DIRECTIONS = () => [
  { value: "BUY", name: i18n.t("tradingSimulationForm.buy") },
  { value: "SELL", name: i18n.t("tradingSimulationForm.sell") },
  { value: "BOTH", name: i18n.t("tradingSimulationForm.both") }
];

export const UNITS = [
  { value: "%", name: "%" },
  { value: "USD", name: "$" }
];

export const TIMEFRAMES = () => [
  { id: 1, name: i18n.t("controlMenu.daily") },
  { id: 2, name: i18n.t("controlMenu.weekly") },
  { id: 3, name: i18n.t("controlMenu.monthly") }
];

export const PRODUCTS = [
  {
    id: "T0",
    name: i18n.t("subscription.tierNames.T0")
  },
  {
    id: "T1",
    name: i18n.t("subscription.tierNames.T1")
  },
  {
    id: "T2",
    name: i18n.t("subscription.tierNames.T2")
  }
];

export const BALANCE_SERIES_CHART_OPTIONS = () => [
  {
    name: i18n.t("patternLab.chartTypes.lines"),
    value: "line"
  },
  {
    name: i18n.t("patternLab.chartTypes.exitOnlyLines"),
    value: "exitOnlyLines"
  },
  {
    name: i18n.t("patternLab.chartTypes.combinedBars"),
    value: "bars"
  }
];

export const PREFERRED_STRATEGY_OPTIONS = () => [
  {
    name: getStrategyTranslations(BALANCED_STRATEGY),
    value: BALANCED_STRATEGY
  },
  {
    name: getStrategyTranslations(LONG_ONLY),
    value: LONG_ONLY
  }
];

export const DATE_TIME_FORMAT = () =>
  i18n.locale === "cs" ? "DD. MM. YYYY" : "DD MMM YYYY";

export const SP_TIMEFRAMES_OPTIONS = () => [
  {
    value: DAILY_REPORT,
    name: getTypeTranslation(DAILY_REPORT)
  },
  {
    value: MONTHLY_REPORT,
    name: getTypeTranslation(MONTHLY_REPORT)
  },
  {
    value: YEARLY_REPORT,
    name: getTypeTranslation(YEARLY_REPORT)
  }
];

export const WIZZARD_STEPS = [
  "/wizzard",
  "/wizzard/step-two",
  "/wizzard/step-three",
  "/wizzard/step-four"
];
