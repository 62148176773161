import { getBaseURL, strategyApiTranslator } from "../utils/helper";
import Vue from "vue";
import Vuex from "vuex";
import adminStore from "./adminStore";
import axios from "axios";
import chartStore from "./chartStore";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { getName } from "country-list";
//import getParsedObject from "../utils/helper";
import orderStore from "./orderStore";
import profileStore from "./profileStore";
import router from "../router";
import stockpickingLabStore from "./stockpickingLabStore";
import subscriptionStore from "./subscriptionStore";
import wizzardStore from "./wizzardStore";

Vue.use(Vuex);

const refreshAuthLogic = failedRequest =>
  axios
    .post(
      "token/refresh/",
      {
        refresh: localStorage.getItem("refreshToken")
      },
      { skipAuthRefresh: true }
    )
    .then(tokenRefreshRes => {
      const access = tokenRefreshRes.data.access;
      const refresh = tokenRefreshRes.data.refresh;
      localStorage.setItem("refreshToken", refresh);
      Vue.$cookies.set("refreshToken", refresh, undefined, undefined, ".analyticalplatform.com");
      Vue.$cookies.set("accessToken", access, undefined, undefined, ".analyticalplatform.com");
      failedRequest.response.config.headers["Authorization"] =
        "Bearer " + access;
      return Promise.resolve();
    })
    .catch(() => {
      return Promise.reject();
    });

function getAccessToken() {
  return Vue.$cookies.get("accessToken");
}

axios.interceptors.request.use(request => {
  if (
    !request.url.includes("token") &&
    !request.url.includes("password/reset")
  ) {
    request.headers["Authorization"] = `Bearer ${getAccessToken()}`;
  }
  return request;
});

createAuthRefreshInterceptor(axios, refreshAuthLogic, {
  statusCodes: [403, 401],
  interceptNetworkError: process.env.VUE_APP_ENV === "local"
});
export default new Vuex.Store({
  state: {
    restAuthUrl: getBaseURL(true),
    user: null,
    isLoggingIn: false,
    isLoggingOut: false,
    loginError: null,
    isInitializing: true,
    registrationStatus: {
      status: "idle"
    }
  },
  mutations: {
    setIsInitializing: (state, data) => (state.isInitializing = data),
    setUser: (state, data) => (state.user = data),
    updateUser: (state, data) => (state.user = { ...state.user, ...data }),
    deleteUser: state => (state.user = null),
    setLoginState: (state, data) => (state.isLoggingIn = data),
    setLoginError: (state, data) => (state.loginError = data),
    setIsLoggingOut: (state, data) => (state.isLoggingOut = data)
  },
  actions: {
    logIn(state, params) {
      state.commit("setLoginState", true);
      state.commit("setLoginError", null);
      return axios
        .post(
          `/token/`,
          {
            username: params.email,
            password: params.password
          },
          { skipAuthRefresh: true }
        )
        .then(res => {
          Vue.$cookies.set("accessToken", res.data.access, undefined, undefined, ".analyticalplatform.com");
          Vue.$cookies.set("refreshToken", res.data.refresh, undefined, undefined, ".analyticalplatform.com");
          localStorage.setItem("refreshToken", res.data.refresh);
          router.push("/welcome");
        })
        .finally(() => state.commit("setLoginState", false));
    },
    checkToken(state) {
      return axios
        .get(`${state.state.restAuthUrl}/user/`)
        .then(res => {
          axios
            .get("sp/upload/salesman")
            .then(() => {
              state.commit("updateUser", {
                isSuperUser: true
              });
            })
            .catch(() => {
              state.commit("updateUser", {
                isSuperUser: false
              });
            });
          state.commit("setUser", {
            ...res.data,
            isLegalEntity: res.data.entity !== "Natural_person",
            preferred_strategy: strategyApiTranslator(
              res.data.preferred_strategy,
              "app"
            ),
            country: {
              code: res.data.country,
              name: getName(res.data.country)
            }
          });
        })
        .catch(() => {
          window.$cookies.remove("accessToken", undefined, ".analyticalplatform.com");
          window.$cookies.remove("refreshToken", undefined, ".analyticalplatform.com");
          localStorage.removeItem("refreshToken");
          state.commit("setIsLoggingOut", false);
          state.commit("deleteUser");
          router.push("/");
        })
        .finally(() => state.commit("setIsInitializing", false));
    },
    logOut(state) {
      state.commit("setIsLoggingOut", true);
      return axios.post(`${state.state.restAuthUrl}/logout/`).then(() => {
        router.push("/");
        state.commit("setIsLoggingOut", false);
        state.commit("deleteUser");
        window.$cookies.remove("accessToken", undefined, ".analyticalplatform.com");
        window.$cookies.remove("refreshToken", undefined, ".analyticalplatform.com");
        localStorage.removeItem("refreshToken");
      });
    },
    register(state, data) {
      return axios.post(`${state.state.restAuthUrl}/registration/`, data);
    },
    changeMyReports(state, payload) {
      axios
        .patch(`${state.state.restAuthUrl}/user/`, {
          favorite_reports: payload
        })
        .then(res => {
          state.commit("setFavoriteReports", res.data.favorite_reports);
        });
    },
    requestPasswordReset(state, data) {
      return axios.post(`${state.state.restAuthUrl}/password/reset/`, data);
    },
    confirmResetPassword(state, data) {
      return axios.post(
        `${state.state.restAuthUrl}/password/reset/confirm/`,
        data
      );
    }
  },
  getters: {
    getRestAuthUrl: state => state.restAuthUrl,
    getUser: state => state.user,
    getLoginStatus: state => state.isLoggingIn,
    getLoginError: state => state.loginError,
    getInitStatus: state => state.isInitializing,
    getRegistrationStatus: state => state.registrationStatus,
    isLoggingOut: state => state.isLoggingOut
  },
  modules: {
    adminStore,
    chartStore,
    orderStore,
    profileStore,
    stockpickingLabStore,
    subscriptionStore,
    wizzardStore
  }
});
