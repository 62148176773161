<template>
  <div>
    <div class="init-spinner" v-if="isInitializing">
      <b-spinner large />
    </div>
    <router-view v-else />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "App",
  computed: {
    ...mapGetters({ isInitializing: "getInitStatus" }),
  },
  methods: {
    ...mapActions(["checkToken"]),
  },
  mounted() {
    this.$cookies.remove("authToken");
    this.$cookies.remove("csrftoken");
    this.$cookies.remove("sessionid");
  },
};
</script>
