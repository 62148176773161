import { BILLING_CYCLE_OPTIONS, TIER_PRODUCTS } from "../utils/constants";
import axios from "axios";
import i18n from "../i18n";

export default {
  namespaced: true,
  state: () => ({
    product: "",
    billingInfo: {
      companyName: "",
      first_name: "",
      last_name: "",
      isLegalEntity: false,
      street: "",
      city: "",
      country: "",
      phone: "",
      CIN: "",
      TIN: "",
      postcode: ""
    },
    availableProducts: null,
    hasCustomized: false,
    billingCycle: BILLING_CYCLE_OPTIONS()[1],
    clientSecret: "",
    paymentState: "idle",
    tierExpiration: null
  }),
  mutations: {
    updateBillingInfo: (state, data) => {
      if (data.isInit && !state.hasCustomized) {
        state.billingInfo = data.billingInfo;
      } else if (!data.isInit) {
        state.billingInfo = data.billingInfo;
        state.hasCustomized = true;
      }
    },
    setProduct: (state, data) => (state.product = data),
    setBillingCycle: (state, data) => (state.billingCycle = data),
    setAvailableProducts: (state, data) => (state.availableProducts = data),
    setClientSecret: (state, data) => (state.clientSecret = data),
    setPaymentState: (state, data) => (state.paymentState = data),
    setTierExpiration: (state, data) => (state.tierExpiration = data)
  },
  actions: {
    getAvailableProducts: state => {
      return axios.get("subscription/tiers/").then(res => {
        const extendedProducts = res.data
          .map(product => ({
            ...product,
            products: TIER_PRODUCTS[product.name],
            displayName: i18n.t(`subscription.tierNames.${product.name}`)
          }))
          .sort(
            (a, b) =>
              parseInt(a.name.substring(1)) - parseInt(b.name.substring(1))
          );
        state.commit("setAvailableProducts", extendedProducts);
      });
    },
    requestPayment: (state, data) => {
      let sd;
      if (data.code) {
        sd = {
          code: data.code
        };
      } else {
        sd = {
          tier: state.state.product.name,
          billing_cycle: data.billingCycle.id,
          billing_info: {
            ...data.billing_info,
            country: data.billing_info.country.code
          }
        };
      }
      return axios
        .post("/payment/order", sd)
        .then(res => state.commit("setClientSecret", res.data));
    },
    getTierExpiration: (state, data) => {
      return axios
        .get(`subscription/offer/${data.tier}/${data.period}`)
        .then(res => state.commit("setTierExpiration", res.data));
    }
  },
  getters: {
    billingInfo: state => state.billingInfo,
    product: state => state.product,
    availableProducts: state => state.availableProducts,
    billingCycle: state => state.billingCycle,
    clientSecret: state => state.clientSecret,
    paymentState: state => state.paymentState,
    tierExpiration: state => state.tierExpiration
  }
};
