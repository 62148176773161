import * as constants from "../utils/constants";
import axios from "axios";

export default {
  namespaced: true,
  state: {
    calculatedRebalance: null,
    isCalculating: false,
    rebalancePdf: null,
    reports: [],
    newReports: [],
    activeReport: [],
    activeDetail: null,
    stockPrices: [],
    favoriteReports: [],
    favoriteReportsActive: [],
    pagination: {
      page: 1,
      pageSize: 10,
      totalRows: null
    },
    loading: {
      report: false,
      newReport: false
    },
    rebalanceReport: null,
    marketTradingHours: null,
    longTickers: null,
    shortTickers: null,
    discardedTickers: [],
    filledTickersLong: [],
    filledTickersShort: [],
    chartData: null,
    selectedStock: null,
    selectedStrategy: null,
    replacerActive: false,
    replacementStock: null,
    rebalancedReportChart: null,
    decomposedReportPdf: null,
    availableTaxYears: [],
    userTaxHistory: []
  },
  mutations: {
    setCalculationStatus: (state, status) => (state.isCalculating = status),
    setCalculatedRebalance: (state, data) => (state.calculatedRebalance = data),
    setRebalancePdf: (state, data) => (state.rebalancePdf = data),
    setReplacementStock: (state, data) => {
      state.replacementStock = data;
    },
    resetDiscarded: state => {
      (state.discardedTickers = []), (state.filledTickersLong = []);
      state.longTickers = state.activeDetail.report_details.slice(0, 20);
    },
    clearSelectedStock: state => (state.selectedStock = null),
    setChartData: (state, data) => (state.chartData = data),
    setReports: (state, data) => (state.reports = data),
    setActiveReport: (state, data) => (state.activeReport = data),
    setActiveDetail: (state, data) => (state.activeDetail = data),
    setNewReports: (state, data) => (state.newReports = data),
    setFavoriteReports: (state, data) => (state.favoriteReports = data),
    setFavoriteReportsActive: (state, data) =>
      (state.favoriteReportsActive = data),
    setLoading: (state, loading) => (state.loading.report = loading),
    setLoadingReportNew: (state, loading) =>
      (state.loading.newReport = loading),
    setPage: (state, data) => (state.pagination.page = data),
    setPerPage: (state, data) => (state.pagination.pageSize = data),
    setTotalRows: (state, data) => (state.pagination.totalRows = data),
    setRebalanceReport: (state, data) => (state.rebalanceReport = data),
    setMarketTradingHours: (state, data) => (state.marketTradingHours = data),
    setLongTickers: (state, data) => (state.longTickers = data),
    setShortTickers: (state, data) => (state.shortTickers = data),
    swapStocks: (state, data) => {
      state.longTickers = [
        ...state.longTickers.filter(lstock => lstock.id !== data.out.report.id),
        data.in.report
      ];
      state.discardedTickers = [...state.discardedTickers, data.out];
      state.filledTickersLong = [...state.filledTickersLong, data.in.report.id];
    },
    clearRebalanceSelecting: state => {
      (state.discardedTickers = []),
        (state.filledTickersLong = []),
        (state.filledTickersShort = []);
    },
    setSelectedStock: (state, data) => {
      state.replacerActive = false;
      state.selectedStock =
        state.selectedStock?.report.id === data.report?.id ? null : data;
    },
    setReplacerActive: (state, data) => {
      state.replacerActive = data;
    },
    setRebalancedReportChart: (state, data) => {
      state.rebalancedReportChart = data;
    },
    setPrices: (state, data) => {
      state.stockPrices = data;
    },
    setDecomposedReportPDF: (state, data) => {
      state.decomposedReportPdf = data;
    },
    setAvailableTaxYears: (state, data) =>  {
      state.availableTaxYears = data
    },
    setUserTaxHistory: (state, data) => {
      state.userTaxHistory = data
    }
  },
  actions: {
    downloadDecomposedPDF({ commit }, reportId) {
      return axios
        .get(`/sp/Report/${reportId}/DecomposedPDF`, {
          responseType: "arraybuffer"
        })
        .then(res => {
          const blob = new Blob([res.data], { type: "application/pdf" });
          commit("setDecomposedReportPDF", blob);
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `Report ${reportId}.pdf`;
          link.click();
        });
    },
    calculateRebalance({ commit, dispatch }, params) {
      commit("setCalculationStatus", true);
      const isLongOnly =
        params.selectedStrategyOption ===
        constants.PREFERRED_STRATEGY_OPTIONS()[1].value;
      let formData = new FormData();
      formData.append("portfolio", params.investedCapital);
      formData.append("file", params.currentPortfolio);
      formData.append("account", params.accountId);
      params.discarded.forEach(ticker => {
        formData.append("discarded", ticker);
      });
      params.selected_longs_ids.forEach(ticker => {
        formData.append("selected_longs_ids", ticker);
      });
      return axios
        .post(
          `sp/Report/${params.reportId}/${
            isLongOnly ? "20Longs" : ""
          }Rebalance`,
          formData
        )
        .then(res => {
          commit("setCalculatedRebalance", {
            ...res.data,
            data: res.data.data.map(stock => ({
              ...stock,
              newPosition: stock.amount_old + stock.order
            }))
          });
          dispatch("getRebalanceInPdf", { data: res.data, isLongOnly });
          dispatch("getAllFavoriteReports");
        })
        .finally(() => commit("setCalculationStatus", false));
    },
    getRebalanceInPdf(state, params) {
      if (params.data) {
        axios
          .post(
            "sp/Report/GeneratePDF",
            { ...params.data, longs_only: params.isLongOnly },
            {
              responseType: "arraybuffer"
            }
          )
          .then(res => {
            const blob = new Blob([res.data], { type: "application/pdf" });
            state.commit("setRebalancePdf", blob);
          });
      }
    },
    getReports(state) {
      state.commit("setLoading", true);
      state.commit("setLoadingReportNew", true);
      return axios
        .get("/sp/Reports")
        .then(res => {
          const newReports = res.data.results.filter(
            report => report.state === constants.NEW_REPORT
          );
          state.commit("setReports", res.data.results),
            state.commit("setNewReports", newReports),
            state.commit("setTotalRows", res.data.count),
            state.commit("setPage", 1),
            state.commit("setPerPage", 10);
        })
        .finally(
          () => state.commit("setLoading", false),
          state.commit("setLoadingReportNew", false)
        )

        .catch(() => {
          state.commit("setReports", []);
        });
    },
    getNewReports(state) {
      state.commit("setLoadingReportNew", true);
      return axios
        .get("/sp/Reports")
        .then(res => {
          const newReports = res.data.results.filter(
            report => report.state === constants.NEW_REPORT
          );
          state.commit("setNewReports", newReports);
        })
        .finally(() => state.commit("setLoadingReportNew", false))

        .catch(() => {
          state.commit("setNewReports", []);
        });
    },
    getReportsWithParams(state, payload) {
      state.commit("setLoading", true);
      return axios
        .get("/sp/Reports", { params: payload })
        .then(res => {
          state.commit("setReports", res.data.results),
            state.commit("setTotalRows", res.data.count);
        })
        .finally(() => state.commit("setLoading", false))
        .catch(() => {
          state.commit("setReports", []);
        });
    },
    getActiveReport({ commit, getters }, payload) {
      if (payload !== null) {
        return axios.get("sp/Report/" + payload).then(res => {
          const reports = getters.getActiveReport;
          if (reports.filter(e => e.id === res.data.id).length === 0) {
            reports.push(res.data);
          }
          commit("setActiveReport", reports);
        });
      } else {
        commit("setActiveReport", []);
      }
    },
    getActiveReportDetail({ commit }, payload) {
      commit("setLoading", true);
      axios
        .get("sp/Reports", {
          params: {
            type: payload,
            page_size: 1
          }
        })
        .then(reportRes => {
          return axios
            .get(`sp/Report/${reportRes.data.results[0].id}`)
            .then(res => {
              commit("setActiveDetail", res.data);
              commit(
                "setLongTickers",
                res.data.report_details
                  .filter(
                    ticker =>
                      ticker.direction.toLowerCase() ===
                      constants.TICKER_LONG.toLowerCase()
                  )
                  .slice(0, 20)
              );
              commit(
                "setShortTickers",
                res.data.report_details
                  .filter(
                    ticker =>
                      ticker.direction.toLowerCase() ===
                      constants.TICKER_SHORT.toLowerCase()
                  )
                  .reverse()
                  .slice(0, 20)
              );
              commit("clearRebalanceSelecting");
            })
            .finally(() => commit("setLoading", false))
            .catch(() => {
              commit("setReports", []);
              commit("setActiveDetail", null);
              commit("setLoading", false);
            });
        });
    },
    getAllFavoriteReports(state) {
      return axios.get("sp/FavoriteReports").then(res => {
        state.commit("setFavoriteReports", res.data.results);
      });
    },
    getFavoriteReports(state, payload) {
      state.commit("setLoading", true);
      return axios
        .get("sp/FavoriteReports", { params: payload })
        .then(res => {
          state.commit("setFavoriteReportsActive", res.data.results),
            state.commit("setTotalRows", res.data.count);
        })
        .finally(() => state.commit("setLoading", false));
    },
    addToFavorite({ dispatch, getters }, payload) {
      return axios.get("sp/Report/" + payload).then(res => {
        const reports = getters.getFavoriteReports;
        reports.push(res.data);
        dispatch("changeMyReports", reports);
      });
    },
    changeMyReports({ rootState, commit }, payload) {
      return axios
        .patch(`${rootState.restAuthUrl}/user/`, {
          favorite_reports: payload
        })
        .then(() => {
          commit("setFavoriteReports", payload);
        });
    },
    getMarketTradingHours(state) {
      return axios
        .get("/sp/market_hours")
        .then(res => state.commit("setMarketTradingHours", res.data));
    },
    getChartData(state, data) {
      state.commit("setChartData", null);
      return axios
        .get(
          `sp/Reports${
            data.strategy === "longOnly" ? "20Longs" : ""
          }Performance/${data.timeframe}`
        )
        .then(res => state.commit("setChartData", res.data));
    },
    getRebalancedReportChartData(state, data) {
      state.commit("setRebalancedReportChart", null);
      return axios
        .get(
          `sp/Report/${data.id}/${
            data.strategy === constants.LONG_ONLY
              ? "Daily20LongsPerformance"
              : "DailyPerformance"
          }`
        )
        .then(res => state.commit("setRebalancedReportChart", res.data));
    },
    getPriceForStock(state, data) {
      return axios
        .get(`datawarehouse/History/${data.symbol}/1`, {
          params: {
            close_only: true,
            from: data.date
          }
        })
        .then(res => {
          const length = Object.keys(res.data).length;
          const values = Object.values(res.data);
          const response = {
            [constants.MONTH]: values[length - 23],
            [constants.FIVE_DAYS]: values[length - 6],
            [constants.DAY]: values[length - 2],
            close_last: values[length - 1]
          };
          state.commit("setPrices", response);
        })
        .catch(() => {
          state.commit("setPrices", []);
        });
    },
    getRebalancedDetail({ commit }, data) {
      commit("setLoading", true);
      return axios
        .get(`sp/Report/${data}`)
        .then(res => {
          commit("setActiveDetail", res.data);
          commit(
            "setLongTickers",
            res.data.report_details
              .filter(
                ticker =>
                  ticker.direction.toLowerCase() ===
                  constants.TICKER_LONG.toLowerCase()
              )
              .slice(0, 20)
          );
          commit(
            "setShortTickers",
            res.data.report_details
              .filter(
                ticker =>
                  ticker.direction.toLowerCase() ===
                  constants.TICKER_SHORT.toLowerCase()
              )
              .reverse()
              .slice(0, 20)
          );
          commit("clearRebalanceSelecting");
        })
        .finally(() => commit("setLoading", false));
    },
    fetchAvailableTaxYears({commit}) {
      return axios.get("taxes/rates")
        .then(res => {
          let availableYears = [];
          if (res.status === 200 && Array.isArray(res.data)) {
            availableYears = res.data.map(rate => rate.year.toString());
          }
          commit("setAvailableTaxYears", availableYears);
        })
        .catch(() => {
          commit("setAvailableTaxYears", []);
        });
    },
    fetchTaxHistory({commit}) {
      return axios.get("taxes/myHistory")
        .then(res => {
          let userTaxHistory = [];
          if (res.status === 200 && Array.isArray(res.data)) {
            userTaxHistory = res.data;
          }
          commit("setUserTaxHistory", userTaxHistory);
        })
        .catch(() => {
          commit("setUserTaxHistory", [])
        })
    }
  },
  getters: {
    filledTickersLong: state => state.filledTickersLong,
    getReports: state => state.reports,
    getNewReports: state => state.newReports,
    getActiveReport: state => state.activeReport,
    activeDetail: state => state.activeDetail,
    getFavoriteReports: state => state.favoriteReports,
    getFavoriteReportsActive: state => state.favoriteReportsActive,
    getLoadingStatus: state => state.loading,
    getPage: state => state.pagination.page,
    getPerPage: state => state.pagination.pageSize,
    getTotalRows: state => state.pagination.totalRows,
    getDatesFrom: state => state.findReport.datesFrom,
    stockPrices: state => state.stockPrices,
    getRebalanceReport: state => state.rebalanceReport,
    getMarketTradingHours: state => state.marketTradingHours,
    shortTickers: state => state.shortTickers,
    longTickers: state => state.longTickers,
    discardedTickers: state => state.discardedTickers,
    chartData: state => state.chartData,
    selectedStock: state => state.selectedStock,
    replacerActive: state => state.replacerActive,
    replacementStock: state => state.replacementStock,
    rebalancedReportChart: state => state.rebalancedReportChart,
    getIsCalculating: state => state.isCalculating,
    getCalculatedRebalance: state => state.calculatedRebalance,
    getRebalanceInPdf: state => state.rebalancePdf,
    decomposedReportPdf: state => state.decomposedReportPdf,
    getAvailableTaxYears: state => state.availableTaxYears,
    getTaxHistory: state => state.userTaxHistory
  }
};
