import axios from "axios";

export default {
  namespaced: true,
  state: {
    users: [],
    usersCount: 0
  },
  mutations: {
    setUsers: (state, data) => (state.users = data),
    setUsersCount: (state, data) => (state.usersCount = data),
    updateUserTier: (state, data) => {
      state.users = state.users.map(user => {
        if (user.id === data.id) {
          return {
            ...user,
            tier: data.tier
          };
        } else {
          return user;
        }
      });
    }
  },
  actions: {
    getUsers: (state, params) => {
      return axios
        .get("/UserDetails", {
          params
        })
        .then(res => {
          state.commit("setUsers", res.data.results);
          state.commit("setUsersCount", res.data.count);
        });
    },
    setTier: (state, data) => {
      return axios.patch(`UserDetails/${data.id}`, data).then(() => {
        state.commit("updateUserTier", data);
      });
    }
  },
  getters: {
    users: state => state.users,
    usersCount: state => state.usersCount
  }
};
