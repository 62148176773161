import {
  email,
  ext,
  max_value,
  min,
  min_value,
  regex,
  required,
  size
} from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import i18n from "./i18n";

extend("required", {
  ...required,
  message: () => i18n.t("validations.required")
});

extend("max_value", {
  ...max_value,
  message: (fieldname, params) =>
    `${i18n.t("validations.maxValue")} ${params.max}`
});

extend("min", {
  ...min,
  message: (fieldName, params) =>
    `${i18n.t("validations.minLength.partOne")} ${params.length} ${i18n.t(
      "validations.minLength.partTwo"
    )}`
});

extend("min_value", {
  ...min_value,
  message: (fieldname, params) =>
    `${i18n.t("validations.minValue")} ${params.min}`
});

extend("regex", {
  ...regex,
  message: fieldName => {
    if (fieldName.toLowerCase().includes("password")) {
      return i18n.t("validations.passwordRegex");
    } else {
      return i18n.t("validations.regex");
    }
  }
});

extend("size", {
  ...size,
  message: () => i18n.t("validations.size")
});

extend("ext", {
  ...ext,
  message: () => i18n.t("validations.wrongFile")
});

extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: () => i18n.t("validations.passMatch")
});
extend("email", {
  ...email,
  message: () => i18n.t("validations.email")
});

extend("isValid", {
  validate(val, params) {
    return params.val;
  },
  params: ["val"],
  message: () => "field not valid"
});

extend("validCountry", {
  validate(val) {
    return !!val.code;
  },
  message: "Provide a valid country"
});

extend("maxRisk", {
  params: ["target"],
  validate(value, { target }) {
    return parseFloat(value) <= parseFloat(target);
  },
  message: () => i18n.t("validations.riskMustBeLowerThanInvestment")
});

extend("maxSl", {
  params: ["target"],
  validate(value, { target }) {
    if (target.value === "%" && value >= 100) {
      return false;
    } else {
      return true;
    }
  },
  message: () => i18n.t("validations.maxSl")
});
