import * as constants from "./constants";
import i18n from "../i18n";

export function getTypeTranslation(type) {
   switch (type) {
      case constants.DAILY_REPORT:
         return i18n.t("stockPickingLab.daily");
      case constants.MONTHLY_REPORT:
         return i18n.t("stockPickingLab.monthly");
      case constants.YEARLY_REPORT:
         return i18n.t("stockPickingLab.annual");
      default: return "";
   }
}

export function getStrategyTranslations(strategy) {
   switch (strategy) {
      case constants.BALANCED_STRATEGY:
         return i18n.t("preferences.strategyOptions.balancedStrategy");
      case constants.LONG_ONLY:
         return i18n.t("preferences.strategyOptions.longOnly");
      default: return "";
   }
}