import axios from "axios";

export default {
  namespaced: true,
  state: {
    selectedStrategy: null,
    selectedTimeframe: null,
    noEdit: false
  },
  mutations: {
    setSelectedStrategy: (state, data) => (state.selectedStrategy = data),
    setSelectedTimeframe: (state, data) => (state.selectedTimeframe = data),
    setNoEdit: (state, data) => (state.noEdit = data)
  },
  actions: {
    updatePreferences(state, data) {
      return axios.patch(`${state.rootState.restAuthUrl}/user/`, {
        preferred_strategy: data
      });
    }
  },
  getters: {
    selectedStrategy: state => state.selectedStrategy,
    selectedTimeframe: state => state.selectedTimeframe,
    noEdit: state => state.noEdit
  }
};
